body {
    height: 100%;
    color: #ead9d9;
    /* background-color: radial-gradient(circle at -4% -12.9%, rgb(74, 98, 110) 0.3%, rgb(30, 33, 48) 90.2%); */
    /* background-image: url(../../public/background.avif); */
    font-family: "Open Sans", sans-serif;
    -webkit-font-smoothing: antialiased;
    margin: 0;
}

.first-container {
    background-image: url('./index.jpg');
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    width: 100vw;
    height: 100vh;
   
}

/* .nav-container {
    height: 58px;
    width: 100%;
    position: fixed;
    color: white;
    letter-spacing: 0.025em;
    transition: background-color 0.2s linear;
    z-index: 2;
}

.nav-container {
    background-color: #80CBC440;
    transition: background-color 0.2s linear;
} */

.nav-container {
    height: 58px;
    width: 100%;
    position: fixed;
    color: white;
    letter-spacing: 0.025em;
    transition: background-color 0.2s linear;
}

.nav-container.solid-nav {
    background-color: #943d23;
    transition: background-color 0.2s linear;
}

.nav-container .nav-row {
    max-width: 1200px;
    width: 100%;
    margin: 0 auto;
    padding-right: 25px;
    padding-left: 25px;
    padding-top: 18px;
}

.nav-container .nav-row .nav-logo {
    display: inline-block;
    font-weight: 900;
}

.nav-container .nav-row .nav-controls {
    float: right;
}

.nav-container .nav-row .nav-controls a {
    padding-left: 20px;
}

a {
    display: inline-block;
    list-style: none;
    text-decoration: none;
    color: white;
    transition: opacity 0.125s linear;
}

a:hover {
    opacity: 0.5;
    cursor: pointer;
    transition: opacity 0.125s linear;
}

a:visited {
    color: inherit;
    text-decoration: none;
}

.container {
    padding-top: 50px;
    padding-bottom: 30px;
    padding-left: 25px;
    padding-right: 25px;
}

.row {
    max-width: 1200px;
    margin: 0 auto;
}

*,
:after,
:before {
    box-sizing: inherit;
}

*,
:after,
:before {
    box-sizing: border-box;
}

.card-container {
    align-items: start;
    display: grid;
    grid-gap: 16px;
    grid-template-columns: repeat(auto-fit, 210px);
    justify-content: center;
    height: 270px;
}

.flip-card {
    position: relative;
    height: 100%;
    border-radius: 5px;
   
    padding: 24px 0 16px;
    text-align: center;
    text-shadow: 1px 1px 0 #555;
}

.content {
    position: absolute;
    width: 100%;
    height: 100%;
    box-shadow: 0 0 15px rgba(0, 0, 0, 0.1);
    transition: transform 1s;
    transform-style: preserve-3d;
}

.flip-card:hover .content {
    transform: rotateY(180deg);
    transition: transform 0.5s;
}

.front,
.back {
    position: absolute;
    height: 100%;
    width: 100%;
    line-height: 300px;
    text-align: center;
    font-size: 60px;
    border-radius: 5px;
    backface-visibility: hidden;
    background: 'transparent';
}

.back {
   
    color: white;
    transform: rotateY(180deg);
}

.para {
    opacity: .8;
    /* width: 480px;
    max-width: 100%; */
    margin-bottom: 37px;
    color: #fff;
    font-size: 16px;
    line-height: 1.5;
}